import React from 'react';

export default function Happy() {
    return (
        <svg
            version="1.1"
            width="68px"
            className="heart-happy"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 709.7 466.6"
        >
            <defs></defs>
            <title>Thank you!</title>

            <g id="plus1">
                <g>
                    <path
                        className="st0"
                        d="M21.2,110.4l-4-5.8c-3-4.4-1.9-10.6,2.5-13.6l77.9-53.5c4.4-3,10.6-1.9,13.6,2.5l4,5.8
			c3,4.4,1.9,10.6-2.5,13.6l-77.9,53.5C30.4,116,24.3,114.8,21.2,110.4z"
                    />
                    <path
                        className="st0"
                        d="M101.5,120.3l-5.8,4c-4.4,3-10.6,1.9-13.6-2.5L28.6,43.8c-3-4.4-1.9-10.6,2.5-13.6l5.8-4
			c4.4-3,10.6-1.9,13.6,2.5l53.5,77.9C107.1,111.1,105.9,117.2,101.5,120.3z"
                    />
                </g>
            </g>
            <g id="plus2">
                <g>
                    <path
                        className="st0"
                        d="M421.6,12.4l-0.6-0.8c-0.4-0.6-0.3-1.5,0.4-2l11.2-7.7c0.6-0.4,1.5-0.3,2,0.4l0.6,0.8c0.4,0.6,0.3,1.5-0.4,2
			l-11.2,7.7C422.9,13.2,422,13,421.6,12.4z"
                    />
                    <path
                        className="st0"
                        d="M433.1,13.8l-0.8,0.6c-0.6,0.4-1.5,0.3-2-0.4l-7.7-11.2c-0.4-0.6-0.3-1.5,0.4-2l0.8-0.6
			c0.6-0.4,1.5-0.3,2,0.4l7.7,11.2C433.9,12.5,433.8,13.3,433.1,13.8z"
                    />
                </g>
            </g>
            <g id="plus3">
                <g>
                    <path
                        className="st1"
                        d="M455.9,446.4l-3.7-5.3c-2.8-4.1-1.8-9.8,2.3-12.6l72.2-49.6c4.1-2.8,9.8-1.8,12.6,2.3l3.7,5.3
			c2.8,4.1,1.8,9.8-2.3,12.6l-72.2,49.6C464.4,451.6,458.7,450.5,455.9,446.4z"
                    />
                    <path
                        className="st1"
                        d="M530.3,455.6l-5.3,3.7c-4.1,2.8-9.8,1.8-12.6-2.3l-49.6-72.2c-2.8-4.1-1.8-9.8,2.3-12.6l5.3-3.7
			c4.1-2.8,9.8-1.8,12.6,2.3l49.6,72.2C535.4,447.1,534.4,452.7,530.3,455.6z"
                    />
                </g>
            </g>
            <g id="circle1">
                <circle className="st2" cx="582.4" cy="66.9" r="14" />
            </g>
            <g id="circle2">
                <g>
                    <g>
                        <path
                            className="st3"
                            d="M247.4,52.8c0.3-7.7,6.2-14.1,14.1-14.1c7.7,0,14.1,6.4,14.1,14.1s-6.4,14.1-14.1,14.1
				C253.5,66.9,247.6,60.5,247.4,52.8c-0.2-5.4-8.7-5.5-8.5,0c0.5,12.3,10,22.6,22.6,22.6c12.3,0,22.6-10.3,22.6-22.6
				c0-12.2-10.3-22.6-22.6-22.6c-12.6,0-22.1,10.3-22.6,22.6C238.7,58.3,247.2,58.3,247.4,52.8z"
                        />
                    </g>
                </g>
                <g>
                    <path
                        className="st4"
                        d="M709.7,217.1c-6.7,7.9-18.5,8.9-26.4,2.2c-6.3-5.3-7.1-14.8-1.7-21.1c4.3-5,11.8-5.7,16.9-1.4
			c4,3.4,4.5,9.5,1.1,13.5c-2.7,3.2-7.6,3.6-10.8,0.9c-2.6-2.2-2.9-6.1-0.7-8.6c1.8-2.1,4.8-2.3,6.9-0.6c1.7,1.4,1.9,3.9,0.5,5.5
			c-1.1,1.3-3.1,1.5-4.4,0.4c-1.1-0.9-1.2-2.5-0.3-3.5"
                    />
                </g>
            </g>
            <g id="star">
                <g>
                    <g>
                        <path
                            className="st5"
                            d="M650.5,107.9c-0.3,1.9-1.7,5.2-1.1,7c0.6,1.8,3.6,3.8,5,5.1c0.2-0.7,0.4-1.3,0.5-2c-1.8,0.3-5.3,0-6.8,1.1
				c-1.5,1.1-2.4,4.5-3.2,6.2c0.7,0,1.4,0,2.1,0c-0.8-1.7-1.7-5.1-3.2-6.2c-1.5-1.1-5-0.9-6.8-1.2c0.2,0.7,0.4,1.3,0.5,2
				c1.4-1.3,4.4-3.2,5-5.1c0.6-1.8-0.8-5.2-1.1-7.1c-0.6,0.5-1.2,0.9-1.8,1.4c1.7,0.9,4.4,3.2,6.4,3.2c1.9,0,4.7-2.3,6.4-3.2
				c1.4-0.7,0.2-2.8-1.2-2.1c-1,0.5-1.9,1-2.9,1.5c-0.6,0.3-1.2,0.6-1.7,0.9c-0.4,0.2-1.7,0.6-0.5,0.6c1.1,0-0.2-0.3-0.5-0.6
				c-0.5-0.4-1.2-0.6-1.7-0.9c-1-0.5-1.9-1-2.9-1.5c-1-0.5-1.9,0.3-1.8,1.4c0.2,1.1,0.4,2.1,0.5,3.2c0.1,0.6,0.2,1.3,0.3,1.9
				c0.1,0.5-0.1,1.6,0.3,0.7c0.4-1-1.8,1.3-2.1,1.7c-0.8,0.8-1.6,1.5-2.3,2.3c-0.6,0.6-0.4,1.9,0.5,2c1.1,0.2,2.1,0.3,3.2,0.5
				c0.6,0.1,3.4,0.6,2.8,0.2c-0.5-0.4,0.8,2.1,1.1,2.6c0.5,1,1,1.9,1.4,2.9c0.4,0.8,1.7,0.8,2.1,0c0.5-1,1-1.9,1.4-2.9
				c0.3-0.6,1.6-3,1.1-2.6c-0.5,0.4,2.3-0.1,2.8-0.2c1.1-0.2,2.1-0.3,3.2-0.5c0.9-0.1,1.1-1.4,0.5-2c-0.8-0.8-1.6-1.5-2.3-2.3
				c-0.5-0.4-2.5-2.7-2.1-1.7c0.4,1,0.2-0.1,0.3-0.7c0.2-0.6,0.2-1.3,0.3-1.9c0.2-1.1,0.4-2.1,0.6-3.2
				C653.1,107,650.8,106.4,650.5,107.9z"
                        />
                    </g>
                </g>
            </g>
            <g id="rectangle">
                <g>
                    <g>
                        <path
                            className="st5"
                            d="M65.4,275.8c-21,13.6-42.1,27.2-63.1,40.8c-3.7,2.4-2.6,7.2,1.1,8.7c23.4,9.4,46.7,18.8,70.1,28.2
				c3.4,1.4,6.8,2.7,10.2,4.1c3.6,1.4,6.6-2.7,5.8-5.8c-5.7-24.4-11.5-48.8-17.2-73.2c-1.4-6-10.6-3.4-9.2,2.5
				c5.7,24.4,11.5,48.8,17.2,73.2c1.9-1.9,3.9-3.9,5.8-5.8c-23.4-9.4-46.7-18.8-70.1-28.2c-3.4-1.4-6.8-2.7-10.2-4.1
				c0.4,2.9,0.8,5.8,1.1,8.7c21-13.6,42.1-27.2,63.1-40.8C75.3,280.7,70.5,272.5,65.4,275.8z"
                        />
                    </g>
                </g>
            </g>
            <g id="ripple">
                <g>
                    <g>
                        <path
                            className="st6"
                            d="M606.4,152.4c6.4,1.8,16.6,5.1,16.7,13.2c0.1,6-5.5,10.6-9.2,14.6c-6.9,7.6-10.3,16.3-3.2,25.1
				c6.7,8.3,21.4,15.2,13.8,27.7c-4.9,8-16.4,16.5-10.1,26.9c3.9,6.5,12.7,8.4,14.9,16.1c2.1,7.4-1.7,16.2-9,18.9
				c-2.5,0.9-1.4,5,1.1,4.1c11-4.1,16.4-18.6,9.9-28.7c-3.8-5.9-14.5-8.9-14.5-16.8c0.1-6.4,6.7-11.6,10.1-16.5
				c3.2-4.6,5.3-10,3.5-15.6c-1.8-5.4-6.5-9.3-10.5-13c-4.5-4.1-10.4-9-8.9-15.8c1.4-6.4,7.8-10.9,11.7-15.8
				c3.9-4.8,6.3-10.7,3.5-16.7c-3.3-7-11.7-9.9-18.6-11.9C604.9,147.6,603.8,151.6,606.4,152.4L606.4,152.4z"
                        />
                    </g>
                </g>
            </g>
            <g id="Layer_1">
                <path
                    className="st7"
                    d="M590.6,173.1c0,142.8-108.4,228.8-185.8,272.1c-5.7,3.2-11.3,6.2-16.7,8.9c0,0,0,0,0,0
		c-17.9,8-38.2,12.6-59.7,12.6c-21.5,0-41.8-4.5-59.6-12.6c0,0,0,0,0,0c-5.4-2.8-11-5.7-16.7-9c0,0,0,0-0.1,0
		c-6.4-3.6-13.1-7.5-19.8-11.7c-15.4-9.5-31.4-20.6-47.2-33.3c-2.4-1.9-4.7-3.8-7.1-5.8c-17.9-15-35.3-32.2-50.8-51.7
		c-35.2-44.2-60.8-100.1-60.8-169.5C66.3,100.7,125,42,197.4,42c68.8,0,125.3,53.1,130.7,120.5c0.3,3.5,0.4,7,0.4,10.6
		c0-4,0.2-7.9,0.5-11.8C334.9,94.4,391.1,42,459.5,42c0.6,0,1.1,0,1.7,0c5.7,0.1,11.3,0.5,16.8,1.3c2.6,0.4,5.2,0.8,7.8,1.3
		c7.4,1.5,14.6,3.6,21.5,6.4c15.9,6.2,30.4,15.5,42.5,27.1c11.2,10.7,20.6,23.4,27.5,37.4C585.8,132.9,590.6,152.4,590.6,173.1z"
                />
            </g>
            <g id="mouth">
                <path
                    className="st8"
                    d="M459.5,327.2c0,27.2-14.8,51.8-38.8,69.5c-23.7,17.6-56.3,28.4-92.3,28.4c-34.6,0-66.1-10-89.5-26.4
		c-25.6-17.9-41.6-43.3-41.6-71.5c0-4,0.3-8,1-11.9c15.1,3.7,165.6,38.7,258.8-6.9C458.7,314.5,459.5,320.8,459.5,327.2z"
                />
                <path
                    className="st9"
                    d="M420.8,396.7c-23.7,17.6-56.3,28.4-92.3,28.4c-34.6,0-66.1-10-89.5-26.4c23.3-10.4,56.5-17,93.3-17
		C366.6,381.8,397.9,387.5,420.8,396.7z"
                />
            </g>
            <g id="glass">
                <g>
                    <g>
                        <circle
                            className="st7"
                            cx="189.7"
                            cy="201.6"
                            r="104.2"
                        />
                        <g>
                            <path
                                className="st10"
                                d="M284.4,201.6c-0.3,39.3-25.1,75.9-62.5,89.1c-37.9,13.4-80.1,1.5-105.7-29.4
					C91,231,88.3,186.3,108.6,152.7c20.2-33.5,60.5-51.8,99.1-44.1C251.9,117.4,284.1,156.6,284.4,201.6c0.1,12.2,19,12.2,18.9,0
					c-0.3-47.5-30.1-90-74.6-106.7c-44.2-16.6-96.5-2.4-126.4,34.1c-30.2,36.9-34.9,90-10.1,131.1C116.9,301,165.2,322,212,313
					c45.5-8.8,82.7-47.2,89.7-93.1c0.9-6.1,1.5-12.2,1.5-18.3C303.4,189.4,284.4,189.4,284.4,201.6z"
                            />
                        </g>
                    </g>
                    <g>
                        <circle
                            className="st7"
                            cx="450.7"
                            cy="212.3"
                            r="104.2"
                        />
                        <g>
                            <path
                                className="st10"
                                d="M545.3,212.3c-0.3,39.3-25.1,75.9-62.5,89.1c-37.9,13.4-80.1,1.5-105.7-29.4
					c-25.2-30.4-27.8-75.1-7.6-108.7c20.2-33.5,60.5-51.8,99.1-44.1C512.9,128.1,545.1,167.3,545.3,212.3c0.1,12.2,19,12.2,18.9,0
					c-0.3-47.5-30.1-90-74.6-106.7c-44.2-16.6-96.5-2.4-126.4,34.1c-30.2,36.9-34.9,90-10.1,131.1c24.7,40.9,73,61.9,119.8,52.9
					c45.5-8.8,82.7-47.2,89.7-93.1c0.9-6.1,1.5-12.2,1.5-18.3C564.4,200.2,545.4,200.2,545.3,212.3z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st10"
                                d="M293.8,211.1c17.7,0,35.5,0,53.2,0c12.2,0,12.2-18.9,0-18.9c-17.7,0-35.5,0-53.2,0
					C281.6,192.2,281.6,211.1,293.8,211.1L293.8,211.1z"
                            />
                        </g>
                    </g>
                </g>
            </g>
            <g id="eyes">
                <g>
                    <g>
                        <path
                            className="st11"
                            d="M194.9,202.8c2.9-1.9,5-4.8,7.4-7.2c3.7-3.7,7.8-7.1,12.5-9.4c16.7-8.4,32.2,3.6,43,15.8
				c4.3,4.8,11.3-2.3,7.1-7.1c-12.7-14.4-31-26.9-50.7-19.4c-6.3,2.4-11.8,6.2-16.7,10.8c-1.8,1.7-3.5,3.5-5.2,5.2
				c-0.6,0.7-1.3,1.3-1.9,2c-0.7,0.8-1.2,1-0.5,0.5C184.5,197.7,189.5,206.3,194.9,202.8L194.9,202.8z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st11"
                            d="M404.7,202.8c2.9-1.9,5-4.8,7.4-7.2c3.7-3.7,7.8-7.1,12.5-9.4c16.7-8.4,32.2,3.6,43,15.8
				c4.3,4.8,11.3-2.3,7.1-7.1c-12.7-14.4-31-26.9-50.7-19.4c-6.3,2.4-11.8,6.2-16.7,10.8c-1.8,1.7-3.5,3.5-5.2,5.2
				c-0.6,0.7-1.3,1.3-1.9,2c-0.7,0.8-1.2,1-0.5,0.5C394.3,197.7,399.3,206.3,404.7,202.8L404.7,202.8z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
