import Author from "component/Author/Author";
import Whitelist from "component/Donate/Whitelist";
import AdsWithType from "component/GAdsense/AdsWithType";
import Layout from "component/Layout/Layout";
import LikeCount from "component/LikeCount/LikeCount";
import SinglePost from "component/Post/Post";
import ScrollButton from "component/ScrollButton/ScrollButton";
import Seo from "component/seo";
import ShareButtons from "component/ShareButtons/ShareButtons";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import SidebarRight from "component/Sidebar/SidebarRight";
import { ArticlesContext } from "context/Articles";
import { graphql, Link } from "gatsby";
import { CommentCount, Disqus } from "gatsby-plugin-disqus";
import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useSiteUrl } from "utils";
import "./blogPost.scss";

const Post = ({ data: { post }, location }) => {
  const {
    id,
    author: { author },
    title,
    contentPost: {
      childMarkdownRemark: { html, timeToRead, tableOfContents },
    },
    // tableOfContents,
    video,
    date,
    sourceCode,
    image,
    url,
    description,
  } = post;

  let disqusConfig = {
    url: "https://homiedev.com/" + url,
    identifier: id,
    title,
  };

  const { posts } = useContext(ArticlesContext);
  const canonicalUrl = `${useSiteUrl()}/${url}`;

  let content = html;
  // link
  content = content.replace(
    /<a href/g,
    `<a rel="noreferrer" target="_blank" class="link" href`
  );
  // table
  content = content.replace(/<table/g, `<div class="homiedev-table"><table`);
  content = content.replace(/<\/table>/g, `</table></div>`);
  // iframe (youtube,...)
  content = content.replace(
    /<iframe/g,
    `<div class="iframe-container"><iframe`
  );
  content = content.replace(/<\/iframe>/g, `</iframe></div>`);

  const imgReg = /<img.src="(.+(?:\.mp4|\.webm))"(?:.+alt="(.*)")?>/gi;
  // because .* with ? match 0 or 1, 0 match => not group => using /img.src="(.+(?:\.mp4|\.webm))"(?:.+alt="(.*)".*)?>

  const videoArr = [...content.matchAll(imgReg)];
  if (videoArr.length) {
    videoArr.forEach((video) => {
      // video[0] = element <img />
      // video[1] = src
      // video[2] = alt

      const element = video[0];
      const src = video[1];
      const HTMLVideoElement = `<video class="vd-content" controls><source src="${src}"></video>`;

      content = content.replace(element, HTMLVideoElement);
    });
  }

  useEffect(() => {
    // 1. extract imgs and src
    const imgs = document.querySelectorAll(".content-article__main img");
    const imgArray = Array.from(imgs);

    // 2. wrap images with div then replace each div using ReactDOM.render
    imgArray.forEach((img, i) => {
      const id = `homiedev-img-${i + 1}`;
      const wrapper = Object.assign(document.createElement("div"), { id });

      img.parentNode.insertBefore(wrapper, img);
      wrapper.appendChild(img);

      ReactDOM.render(
        <Zoom>
          <img src={img.src} alt={img.alt} />
        </Zoom>,
        document.querySelector(`#${id}`)
      );
    });
  }, []);

  const shareButtons = (
    <ShareButtons size={36} title={title} url={canonicalUrl} />
  );

  const likeCount = <LikeCount postId={id} title={title} />;

  return (
    <Layout TOC={tableOfContents}>
      <Seo
        article={true}
        title={title}
        image={image.file.url}
        description={description}
        canonicalUrl={canonicalUrl}
      />

      <SidebarLeft />

      <div className="content-article">
        <Whitelist />
        {video && (
          <div className="video-wrapper">
            <div className="video-wrapper__content">
              <iframe
                title="Watch video"
                width="100%"
                height="523"
                src={`https://www.youtube.com/embed/${video}?rel=0`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="video-iframe"
              />
            </div>
          </div>
        )}
        <div className="content-article__main">
          <div className="content-header">
            <div className="post-info">
              <h1 className="post-title">{title}</h1>
              <div className="post-description">
                <Link to="/creator/" className="author">
                  {author}
                </Link>
                <span className="datetime">{date}</span>
                <span className="timeToRead">{timeToRead} min read</span>
                <CommentCount config={disqusConfig} placeholder={"..."} />
              </div>
              {sourceCode && (
                <a
                  className="link"
                  href={sourceCode}
                  target="_blank"
                  rel="noreferrer"
                >
                  Source Code
                </a>
              )}
            </div>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
        <div className="share-wrapper">
          <div className="share-title">
            Nếu bạn thấy bài viết hay, hãy chia sẻ cho bạn bè cùng đọc nhé!
          </div>
          {shareButtons}
        </div>
        {posts?.length > 0 && (
          <section className="new-posts">
            <div className="title">Bài viết mới 🎉</div>
            <div className="posts">
              {posts.slice(0, 2).map((post) => {
                return <SinglePost key={post.id} post={post} />;
              })}
            </div>
          </section>
        )}
        <section className="new-posts">
          <div className="title">Có thể bạn thích ⚡</div>
          <div className="posts">
            <AdsWithType type="multiplex" />
          </div>
        </section>
        <Author />
        <Disqus config={disqusConfig} />
      </div>
      <SidebarRight likeCount={likeCount} />

      <ScrollButton />
    </Layout>
  );
};

export const query = graphql`
  query getSinglePost($url: String) {
    post: contentfulPosts(url: { eq: $url }) {
      id
      sourceCode
      contentPost {
        contentPost
        childMarkdownRemark {
          tableOfContents(maxDepth: 4)
          timeToRead
          html
        }
      }
      description
      video
      tags {
        id
        name
        url
      }
      date(formatString: "MMM DD, YYYY")
      url
      author {
        author
      }
      title
      image {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          width: 300
        )
      }
    }
  }
`;

export default Post;
