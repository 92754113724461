const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: "homiedev-cff72.firebaseapp.com",
  projectId: "homiedev-cff72",
  storageBucket: "homiedev-cff72.appspot.com",
  messagingSenderId: "265656822985",
  appId: "1:265656822985:web:af54768f7bc8a4bb1cf118",
  measurementId: "G-BBYEEDP78Z",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export { firebaseConfig };
