import React from "react";
import { css } from "@emotion/react";
import { navigate } from "gatsby";
import { RiHeart2Fill } from "react-icons/ri";
import { pageLinks } from "utils";

const whiteListCss = {
  self: css`
    border: 1px solid var(--color-border);
    padding: 1rem;
    margin-bottom: 2rem;
    max-width: 340px;
    font-size: 1.6rem;
  `,
  bTag: css`
    color: var("--color-secondary");
  `,
  button: css`
    font-family: var(--font-family-one);
    color: #7c2b48;
    border-radius: 2rem;
    background-color: #febf4d;
    padding: 10px;
    width: 100%;
    border: none;
    cursor: pointer;
    margin: 1rem 0;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.6rem;

    svg {
      margin-left: 3px;
    }

    &:active {
      outline: none;
    }

    &:hover {
      transform: translateY(2px);
    }
  `,
};

const Whitelist = () => {
  return (
    <div css={whiteListCss.self}>
      Hi 🤓 Cảm ơn bạn đã ghé thăm blog này, nếu những bài viết trên blog giúp
      ích cho bạn. Bạn có thể giúp blog hiển thị quảng cáo bằng cách tạm ngừng{" "}
      <b css={whiteListCss.bTag}>ad blocker</b> 😫 và{" "}
      <button
        onClick={() => {
          navigate(pageLinks.donate);
        }}
        css={whiteListCss.button}
      >
        Ủng hộ
        <RiHeart2Fill />
      </button>
      để giúp blog duy trì hoạt động nếu bạn muốn.
      <br /> Cảm ơn bạn!
    </div>
  );
};

export default Whitelist;
