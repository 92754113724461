import React, { useState, useEffect } from "react";
import HeartLook from "../../../assets/Look";
import HeartHappy from "../../../assets/Happy";
import HeartShy from "../../../assets/Shy.svg";
import { firebaseConfig } from "../../firebase/firebase.utils";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import "./LikeCount.scss";

let firestore;
export default function LikeCount({ postId, title }) {
  const [height, setDimension] = useState(0);
  const [count, setCount] = useState(0);
  const [numOfLikes, setNum] = useState(0);
  const [stateIcon, setState] = useState(false);
  const [updating, setUpdate] = useState(false);

  useEffect(() => {
    const lazyApp = import("firebase/app");

    lazyApp.then(({ initializeApp }) => {
      initializeApp(firebaseConfig);
      // getAnalytics(app);

      if (!firestore) {
        firestore = getFirestore();
      }

      const docRef = doc(firestore, "posts", postId);
      getDoc(docRef)
        .then((data) => {
          if (data.exists()) {
            const post = data.data();
            setNum(post.like_count);
          }
          try {
            const result = getLocalStorage("homie");

            if (!result.length) {
              setLocalStorage("homie", [initialPostObject(postId, 0)]);
            } else {
              const postInLocalStorage = result.find(
                (item) => item.post === postId
              );

              if (
                !postInLocalStorage ||
                !is("number", postInLocalStorage.count) ||
                isNaN(postInLocalStorage.count)
              ) {
                result.push(initialPostObject(postId, 0));
                setLocalStorage("homie", result);
              } else {
                const num = Math.abs(postInLocalStorage.count);
                if (is("number", num) && num <= 10) {
                  setCount(num);
                  setDimension(num * 10);
                } else {
                  setCount(0);
                }
              }
            }
          } catch (error) {
            setLocalStorage("homie", [initialPostObject(postId, 0)]);
          }
          setState(true);
        })
        .catch((error) => {
          setState(false);
        });
    });
  }, [postId]);

  function getLocalStorage(key) {
    try {
      return localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem("homie"))
        : [];
    } catch (error) {
      return [];
    }
  }

  function updateLike(numOfLikes) {
    if (firestore) {
      const docRef = doc(firestore, "posts", postId);
      setUpdate(true);

      const updated_at = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Ho_Chi_Minh",
      });
      setDoc(docRef, {
        title,
        like_count: numOfLikes,
        updated_at: Timestamp.fromDate(new Date(updated_at)),
      })
        .then(() => setUpdate(false))
        .catch((error) => {
          setState(false);
        });
    }
  }

  function setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  function is(type, value) {
    return typeof value === type;
  }

  function filterPost(postId, arr) {
    return arr.filter((item) => item.post !== postId);
  }

  function initialPostObject(postId, count) {
    return { post: postId, count };
  }

  function handleClick(n = 1) {
    let increase = count + n;
    if (increase <= 10) {
      try {
        const total = numOfLikes + n;
        updateLike(total);
        setCount(increase);
        setNum(total);

        const posts = getLocalStorage("homie");
        const otherPost = filterPost(postId, posts);

        otherPost.push(initialPostObject(postId, increase));

        setLocalStorage("homie", otherPost);

        const gradientHeight = 10 * increase;
        setDimension(gradientHeight);
      } catch (error) {
        setLocalStorage("homie", [initialPostObject(postId, increase)]);
      }
    }
  }

  return (
    <div className="likeCount_wrapper">
      <div className="data">
        {!stateIcon ? (
          <div className="icon">
            <HeartShy />
          </div>
        ) : (
          <>
            <button
              disabled={updating}
              onClick={() => handleClick()}
              className="icon"
            >
              {count === 10 || height === 100 ? (
                <HeartHappy />
              ) : (
                <HeartLook percent={height} />
              )}
            </button>
            <div className="count">{numOfLikes}</div>
          </>
        )}
      </div>
    </div>
  );
}
