import React from "react";

export default function Look({ percent }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 524.3 424.6"
      className="heart-look"
    >
      <defs>
        <linearGradient id="Gradient" gradientTransform="rotate(90)">
          <stop offset="5%" stopColor="#bc4e9c" />
          <stop offset="95%" stopColor="#f80759" />
        </linearGradient>

        <clipPath id="heart-gradient">
          <rect
            x="0"
            y="0"
            style={{
              transform: "rotate(180deg)",
              transformOrigin: "center",
            }}
            width="100%"
            height={percent + "%"}
            fill="url(#Gradient)"
          />
        </clipPath>
      </defs>
      <title>Click vào tui để like bài viết</title>
      <g id="heart-shape">
        <path
          id="heart-path-copy"
          fill=""
          d="M524.3,131.1c0,142.8-108.4,228.8-185.8,272.1c-5.7,3.2-11.3,6.2-16.7,8.9c0,0,0,0,0,0
		c-17.9,8-38.2,12.6-59.7,12.6c-21.5,0-41.8-4.5-59.6-12.6c0,0,0,0,0,0c-5.4-2.8-11-5.7-16.7-9c0,0,0,0-0.1,0
		c-6.4-3.6-13.1-7.5-19.8-11.7c-15.4-9.5-31.4-20.6-47.2-33.3c-2.4-1.9-4.7-3.8-7.1-5.8c-17.9-15-35.3-32.2-50.8-51.7
		C25.6,256.4,0,200.5,0,131.1C0,58.7,58.7,0,131.1,0c68.8,0,125.3,53.1,130.7,120.5c0.3,3.5,0.4,7,0.4,10.6c0-4,0.2-7.9,0.5-11.8
		C268.6,52.4,324.8,0,393.2,0c0.6,0,1.1,0,1.7,0c5.7,0.1,11.3,0.5,16.8,1.3c2.6,0.4,5.2,0.8,7.8,1.3c7.4,1.5,14.6,3.6,21.5,6.4
		c15.9,6.2,30.4,15.5,42.5,27.1c11.2,10.7,20.6,23.4,27.5,37.4C519.5,90.9,524.3,110.4,524.3,131.1z"
        />
        <path
          className="st0"
          d="M524.3,131.1c0,142.8-108.4,228.8-185.8,272.1c-5.7,3.2-11.3,6.2-16.7,8.9c0,0,0,0,0,0
		c-17.9,8-38.2,12.6-59.7,12.6c-21.5,0-41.8-4.5-59.6-12.6c0,0,0,0,0,0c-5.4-2.8-11-5.7-16.7-9c0,0,0,0-0.1,0
		c-6.4-3.6-13.1-7.5-19.8-11.7c-15.4-9.5-31.4-20.6-47.2-33.3c-2.4-1.9-4.7-3.8-7.1-5.8c-17.9-15-35.3-32.2-50.8-51.7
		C25.6,256.4,0,200.5,0,131.1C0,58.7,58.7,0,131.1,0c68.8,0,125.3,53.1,130.7,120.5c0.3,3.5,0.4,7,0.4,10.6c0-4,0.2-7.9,0.5-11.8
		C268.6,52.4,324.8,0,393.2,0c0.6,0,1.1,0,1.7,0c5.7,0.1,11.3,0.5,16.8,1.3c2.6,0.4,5.2,0.8,7.8,1.3c7.4,1.5,14.6,3.6,21.5,6.4
		c15.9,6.2,30.4,15.5,42.5,27.1c11.2,10.7,20.6,23.4,27.5,37.4C519.5,90.9,524.3,110.4,524.3,131.1z"
        />
      </g>
      <g id="shadow">
        <path
          className="st1"
          d="M524.3,131.1c0,142.8-108.4,228.8-185.8,272.1c-5.7,3.2-11.3,6.2-16.7,8.9c0,0,0,0,0,0
		c-17.9,8-38.2,12.6-59.7,12.6c-21.5,0-41.7-4.5-59.6-12.6c0,0,0,0,0,0c-5.4-2.8-11-5.7-16.7-9c0,0,0,0-0.1,0
		c-6.4-3.6-13.1-7.5-19.8-11.7c-15.4-9.5-31.4-20.6-47.2-33.3c-2.4-1.9-4.7-3.8-7.1-5.8c105.1,46.3,190.5,12,190.5,12
		c145.2-57.6,157.9-203.6,157.9-203.6c6-41.5-13.2-91.8-30.6-127.1C418.1,17.4,406.4,6.8,394.9,0c5.7,0.1,11.3,0.5,16.8,1.3
		c2.6,0.4,5.2,0.8,7.8,1.3c7.4,1.5,14.6,3.6,21.5,6.4c15.9,6.2,30.4,15.5,42.6,27.1c11.2,10.7,20.6,23.4,27.5,37.4
		C519.5,90.9,524.3,110.4,524.3,131.1z"
        />
      </g>

      <use
        clipPath="url(#heart-gradient)"
        xlinkHref="#heart-path-copy"
        fill="url(#Gradient)"
      />

      <g id="mouth">
        <g>
          <g>
            <path
              className="st2"
              d="M196.2,313.5c24.7,19.4,59.8,28.9,90.5,21.6c15.5-3.6,29.3-11.5,41.4-21.6c5-4.1-2.2-11.2-7.1-7.1
				c-23.2,19.3-52.3,25.6-81.3,17.5c-9.3-2.6-18.1-6.4-26.5-11.1c-2.7-1.5-5.4-3.2-8-4.9c-0.7-0.4-4.2-3.1-2.1-1.4
				C198.2,302.5,191.1,309.6,196.2,313.5L196.2,313.5z"
            />
          </g>
        </g>
      </g>
      <g id="glass">
        <g>
          <g>
            <g>
              <path
                className="st3"
                d="M196.5,160.8c-0.3,30.9-19.6,59.8-48.9,70.4c-30,10.8-63.6,1.7-84-22.6c-20.2-24.1-22.5-59.6-6.4-86.4
					c16-26.7,48.2-41.2,78.8-35C171,94.2,196.2,125.1,196.5,160.8c0.1,9.6,15.1,9.7,15,0c-0.3-37.9-24.2-71.9-59.9-84.9
					c-34.9-12.7-76-1.3-99.5,27.5c-23.8,29.2-27.5,71.1-7.9,103.6c19.5,32.5,58,49.3,95.1,42.1c41.8-8.1,71.9-46.1,72.3-88.3
					C211.6,151.1,196.6,151.1,196.5,160.8z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className="st3"
                d="M403.4,169.3c-0.3,30.9-19.6,59.8-48.9,70.4c-30,10.8-63.6,1.7-84-22.6c-20.2-24.1-22.5-59.6-6.4-86.4
					c16-26.7,48.2-41.2,78.8-35C377.8,102.7,403.1,133.6,403.4,169.3c0.1,9.6,15.1,9.7,15,0c-0.3-37.9-24.2-71.9-59.9-84.9
					c-34.9-12.7-76-1.3-99.5,27.5c-23.8,29.2-27.5,71.1-7.9,103.6c19.5,32.5,58,49.3,95.1,42.1c41.8-8.1,71.9-46.1,72.3-88.3
					C418.5,159.6,403.5,159.6,403.4,169.3z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className="st3"
                d="M204,168.3c14.1,0,28.1,0,42.2,0c9.7,0,9.7-15,0-15c-14.1,0-28.1,0-42.2,0
					C194.4,153.3,194.4,168.3,204,168.3L204,168.3z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="eyes">
        <circle className="st2" cx="162.7" cy="184.3" r="19.3" />
        <circle className="st2" cx="371.1" cy="184.3" r="19.3" />
      </g>
    </svg>
  );
}
