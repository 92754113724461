import React, { useCallback } from "react";

const AdsWithType = ({ type, className }) => {
  React.useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  const createAds = useCallback(
    (slot, layoutKey, adFormat = "auto") => {
      return (
        <div className={className ? className + " ads-wrapper" : "ads-wrapper"}>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-format={adFormat}
            data-ad-layout-key={layoutKey}
            data-ad-client="ca-pub-7386234541425278"
            data-ad-slot={slot}
          ></ins>
        </div>
      );
    },
    [className]
  );

  let ads = null;

  switch (type) {
    case "image-right-side":
      ads = createAds("1688535977", "-bi+bk-2f-53+ik", "fluid");
      break;

    case "header":
      ads = (
        <ins
          className="adsbygoogle"
          style={{ display: "inline-block", width: "728px", height: "90px" }}
          data-ad-client="ca-pub-7386234541425278"
          data-ad-slot="9587282921"
        ></ins>
      );

      break;

    case "auto":
      ads = createAds("9032776782", undefined);
      break;

    case "text":
      ads = createAds("1279495952", "-in+8-k-t+3t", "fluid");
      break;

    case "post":
      ads = createAds("9503145498", "-gs-11+26-ay+lt", "fluid");
      break;

    case "banner":
      ads = createAds("2494809667", undefined);
      break;

    case "multiplex":
      ads = createAds("7752345120", undefined, "autorelaxed");
      break;

    default:
      break;
  }

  return ads;
};

export default AdsWithType;
