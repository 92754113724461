import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import styled from "@emotion/styled";

const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }
`;

const ShareButtons = ({ size = 20, url }) => {
  return (
    <StyledButtons className="share-buttons">
      <FacebookShareButton title="Chia sẻ lên Facebook" url={url}>
        <FacebookIcon size={size} round />
      </FacebookShareButton>
      <TwitterShareButton title="Chia sẻ lên Twitter" url={url}>
        <TwitterIcon size={size} round />
      </TwitterShareButton>
      <LinkedinShareButton title="Chia sẻ lên Linkedin" url={url}>
        <LinkedinIcon size={size} round />
      </LinkedinShareButton>
    </StyledButtons>
  );
};

export default ShareButtons;
