import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import "./Author.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Author() {
  const author = useStaticQuery(graphql`
    {
      contentfulProfile {
        nickname
        photo {
          gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1, width: 110)
        }
      }
    }
  `);
  const {
    contentfulProfile: { photo, nickname },
  } = author;

  const image = getImage(photo);

  return (
    <div className="author-content">
      <div className="about-author">
        <div className="info-author">
          <GatsbyImage image={image} alt={"homiedev"} />
        </div>
        <div className="summary">
          <div className="summary__title">About Me</div>
          <p>
            Hi, I'm <b className="nickname">{nickname}</b>. Một người thích chia
            sẻ kiến thức, đặc biệt là về&nbsp;
            <b className="frontend-text">Frontend</b> 🚀. Trang web này được tạo
            ra nhằm giúp các bạn học&nbsp;
            <b className="frontend-text">Frontend</b>&nbsp;hiệu quả hơn 🎉😄.
          </p>
          <p>Chúc các bạn tìm được kiến thức hữu ích trong blog này 😁😁.</p>
        </div>
      </div>
    </div>
  );
}
