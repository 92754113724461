import React, { useEffect, useState } from "react";
import { ImArrowUp } from "react-icons/im";
import styled from "@emotion/styled";

const Button = styled.div`
  position: fixed;
  right: 50px;
  bottom: 30px;
  padding: 1rem;
  font-weight: 900;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  z-index: 100;

  @media screen and (max-width: 425px) {
    right: 20px;
    padding: 0.5rem;

    svg {
      font-size: 20px;
    }
  }

  &,
  &:hover,
  &:active {
    color: #3aa3e3 !important;
    background-color: #e8e8e8;
    font-weight: 900;
    text-shadow: none;
  }

  &:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.25rem;
  }

  &:hover:after,
  &:focus:after {
    box-shadow: inset 0 -2px rgba(0, 0, 0, 0.25);
  }

  &:active:after {
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
  }
`;

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = window.pageYOffset;

      if (scrolled > 600) {
        setVisible(true);
      } else if (scrolled <= 600) {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisible);

    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  return (
    <Button
      title="Lên đầu trang"
      onClick={scrollToTop}
      style={{ display: visible ? "inline" : "none" }}
    >
      <ImArrowUp fontSize={24} />
    </Button>
  );
};

export default ScrollButton;
